import { DB } from "dcp-types"
import { getDomain } from "./env"

export const DebounceDelay = {
  FastInputChange: 500,
  InputChange: 1000,
  InputIdle: 5000,
  LoadingState: 300,
  HoverElementEndDelay: 500,
  AcknowledgeMessage: 2000,
  TransitionDuration: 300,
  CollapseTransitionDuration: 600,
  SelectAfterFocus: 50,
  FastClick: 300,
} as const

export const TimeoutDelay = {
  EditorInitialization: 350,
  Idle: 1500,
  LazyLoad: 250,
}
export const Intervals = {
  DocumentListRefetch: 30_000, // 30 seconds
  SystemInformationRefetch: 60_000, // 1 minute
  NotificationRefetch: 600_000, // 10 minutes
  DashboardRefetch: 300_000, // 5 minutes
}

export const getSetPasswordUrl = (
  email: string,
  token: string,
  type: "invite" | "reset",
) => {
  const domain = getDomain()

  return `${domain}/de/auth/activate-session?callbackUrl=${encodeURIComponent(
    "/de/auth/set-password",
  )}&email=${email}&${
    type === "invite" ? "invite_token" : "reset_token"
  }=${token}`
}
